@import "~schaffrath/variables";
@import "~base/experience/components/commerceLayouts/carousel";

.carousel-item {
	padding: 0;
}

.carousel {
	.row {
		margin-right: 0;
		margin-left: 0;

		.col,
		[class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}

	@include media-breakpoint-only(xs) {
		&[data-xs="1"] {
			.carousel-item {
				margin-right: -100%;
			}
		}
	}

	@include media-breakpoint-between(sm, md) {
		// data-sm configures amount of slides on tablet (and some smartphones landscape)
		&[data-sm="1"] {
			.carousel-item {
				margin-right: -100%;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		// data-md configures amount of slides on desktop (and tablet landscape)
		&[data-md="1"] {
			.carousel-item {
				margin-right: -100%;
			}
		}
	}
}

.experience-einstein-einsteinCarousel,
.experience-einstein-einsteinCarouselCategory,
.experience-einstein-einsteinCarouselProduct,
.experience-commerce_layouts-carousel,
.experience-commerce_layouts-grid1r1cMainCentral {

	@include media-breakpoint-down(md) {
		max-width: 100%;
	}
}

.experience-einstein-einsteinCarousel .carousel-control-prev,
.experience-einstein-einsteinCarouselCategory .carousel-control-prev,
.experience-einstein-einsteinCarouselProduct .carousel-control-prev,
.experience-commerce_layouts-carousel .carousel-control-prev {
	left: 0;

	@include media-breakpoint-down(sm) {
		height: 3.125rem; // 50px
		width: 1.875rem; // 30px
	}
}

.experience-einstein-einsteinCarousel .carousel-control-next,
.experience-einstein-einsteinCarouselCategory .carousel-control-next,
.experience-einstein-einsteinCarouselProduct .carousel-control-next,
.experience-commerce_layouts-carousel .carousel-control-next {
	right: 0;

	@include media-breakpoint-down(sm) {
		height: 3.125rem; // 50px
		width: 1.875rem; // 30px
	}
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	top: 35%;

	@include media-breakpoint-down(sm) {
		height: 0.9375rem;
		width: 0.9375rem;
	}
}

.pd-carousel-indicators {
	z-index: 2;
}

.experience-commerce_layouts-carousel {
    .component--full-screen-width {
        .carousel-control-prev {
            left: 1px;
        }
        .carousel-control-next {
            right: 1px;
        }
    }

    .carousel {
        &[class*="indicators-"] {
            padding-bottom: 0;
            margin-bottom: 4.375rem;
        }

        &[class*="controls-"] {
            .carousel-control-prev,
            .carousel-control-next {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .pd-carousel-indicators {
            margin-bottom: 0;
            bottom: -2.5rem;
        }
    }
}
